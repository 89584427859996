class Menu {
    constructor() {
        this.subMenuItems = document.querySelectorAll('#header .site-nav > li')
        this.subMenuLinks = document.querySelectorAll('#header .site-nav > li.has-submenu > a')
        this.subMenuItemsSVG = document.querySelectorAll('#header .site-nav > li svg')

        const isMobile = window.matchMedia('only screen and (max-width: 1024px)').matches;

        if (isMobile) {
            this.subMenuLinks.forEach(item => {
                item.addEventListener('click', e => {
                    const target = e.target.getAttribute('href')
                    if (target == '#') {
                        const subMenu = e.target.parentElement
                        subMenu.classList.toggle('has-submenu--hover')
                    }
                });
            });

            this.subMenuItemsSVG.forEach(item => {
                item.addEventListener('click', e => {
                    const subMenu = e.target.parentElement
                    subMenu.classList.toggle('has-submenu--hover')
                });
            });
        } else {
            this.subMenuItems.forEach(item => {
                item.addEventListener('mouseenter', e => {
                    const id = item.getAttribute('id');
                    this.subMenuItems.forEach(i => {
                        i.classList.remove('has-submenu--hover')
                    });

                    item.classList.add('has-submenu--hover')
                });

                item.addEventListener('mouseleave', e => {
                    const id = item.getAttribute('id');
                    this.subMenuItems.forEach(i => {
                        const iId = i.getAttribute('id');
                        if (id != iId && i.classList.contains('has-submenu--hover'))
                            i.classList.remove('has-submenu--hover')
                    });

                    if (!item.classList.contains('has-submenu')) {
                        this.subMenuItems.forEach(i => {
                            i.classList.remove('has-submenu--hover')
                        });
                    }

                    if (item.classList.contains('has-submenu--hover'))
                        item.classList.remove('has-submenu--hover')
                });
            });
        }
    }
}

export default Menu;